import styled, { css } from 'styled-components'
import media from 'styles/media'

import { H2Bold } from 'components/atoms/Typography'
import { Wrapper as BreadcrumbsWrapper } from 'components/modules/Breadcrumbs/Breadcrumbs.styles'

export const Wrapper = styled.div`
  margin-top: 95px;

  ${media.sm.max} {
    margin-top: 65px;
  }
`

export const BreadcrumbsWraper = styled.div`
  max-width: 1136px;
  padding: 0 40px;
  margin: 0 auto;
  box-sizing: content-box;

  ${BreadcrumbsWrapper} {
    border: none;
    border-top: 1px solid ${({ theme }) => theme.colors.gray05};
  }

  ${media.sm.max} {
    padding: 0;
  }
`

export const StyledContainer = styled.div`
  max-width: 1136px;
  padding: 0 40px;
  margin: 0 auto;
  box-sizing: content-box;

  ${media.lg.max} {
    padding: 0 24px;
  }
`

export const HeadingWrapper = styled.div`
  display: grid;
  gap: 16px;
  padding: 72px 24px;
  max-width: 694px;
  margin: 0 auto;

  ${media.lg.max} {
    gap: 10px;
    padding: 48px 0;
  }
`

export const ImagesWrapper = styled.div``

export const BigImageWrapper = styled.div`
  width: 100%;
  height: 480px;
  max-width: 1136px;
  margin: 0 auto;

  ${media.lg.max} {
    height: 320px;
  }
`

export const ImageThumbnailsWrapper = styled.div`
  padding: 16px 0 0 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, 64px);
  gap: 8px;

  ${media.lg.max} {
  }
`

export const ThumbnailImageWrapper = styled.div<{ active: boolean }>`
  width: 64px;
  height: 64px;
  cursor: pointer;
  box-sizing: border-box;

  ${({ active }) =>
    active &&
    css`
      border: 2px solid ${({ theme }) => theme.colors.primary};
    `}
`

export const ArticleContentWrapper = styled.div`
  padding: 100px 0;
  max-width: 700px;
  margin: 0 auto;

  ${H2Bold} {
    margin-bottom: 20px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 10px;
  }

  ${media.lg.max} {
    padding: 48px 0;

    ${H2Bold} {
      margin-bottom: 16px;
    }
  }
`
