import React from 'react'
import { graphql, PageProps } from 'gatsby'
import { Layout } from 'views/Layout'
import { Seo } from 'components/atoms/Seo'
import { Contact } from 'components/organisms/Contact'
import { NewsArticle } from 'components/organisms/NewsArticle'
import { MoreNews } from 'components/modules/MoreNews'

const WpisAktualnosciPage: React.FC<
  PageProps<Queries.WpisAktualnosciPageQuery>
> = ({ data }) => {
  const PAGE = data.wpNewsSingle?.newsSingle
  const PAGE_SEO = data.wpNewsSingle?.seo!

  if (!PAGE || !PAGE_SEO) throw new Error("CMS data didn't load properly")

  const ARTICLE_IMAGES =
    PAGE.newsSingleGallery?.map((image) => ({
      src: image?.newsSingleGalleryImg?.localFile?.childImageSharp
        ?.gatsbyImageData!,
      alt: image?.newsSingleGalleryImg?.altText!,
    })) || []

  const OTHER_NEWS =
    data.allWpNewsSingle.nodes?.map((news) => ({
      image: {
        src: news.newsSingle?.newsSingleGallery?.[0]?.newsSingleGalleryImg
          ?.localFile?.childImageSharp?.gatsbyImageData!,
        alt:
          news.newsSingle?.newsSingleGallery?.[0]?.newsSingleGalleryImg
            ?.altText! ?? '',
      },
      heading: news.title!,
      text: news.newsSingle?.newsSingleExcerpt!,
      date: new Date(news.date!).toLocaleDateString(),
      link: `/aktualnosci/${news.slug}`,
    })) || []

  return (
    <Layout>
      <Seo
        title={PAGE_SEO.title ?? ''}
        description={PAGE_SEO.metaDesc ?? ''}
        ogTitle={PAGE_SEO.opengraphTitle}
        ogDescription={PAGE_SEO.opengraphDescription}
        ogImage={PAGE_SEO.opengraphImage?.sourceUrl}
        twitterTitle={PAGE_SEO.twitterTitle}
        twitterDescription={PAGE_SEO.twitterDescription}
        twitterImage={PAGE_SEO.twitterImage?.sourceUrl}
      />

      <NewsArticle
        heading={data.wpNewsSingle.title!}
        date={new Date(data.wpNewsSingle.date!).toLocaleDateString()}
        images={ARTICLE_IMAGES}
        contentHeading={PAGE.newsSingleTitle!}
        content={PAGE.newsSingleDescription!}
        crumbs={[
          { name: 'Aktualności', to: '/aktualnosci' },
          { name: data.wpNewsSingle.title! },
        ]}
      />
      <Contact
        heading={PAGE.newsSingleFormTitle!}
        formTitle={PAGE.newsSingleFormFormTitle!}
        image={{
          src: PAGE.newsSingleFormImg?.localFile?.childImageSharp
            ?.gatsbyImageData!,
          alt: PAGE.newsSingleFormImg?.altText!,
        }}
      />
      <MoreNews heading="Więcej<br/> aktualności" cards={OTHER_NEWS} />
    </Layout>
  )
}

export default WpisAktualnosciPage

export const query = graphql`
  query WpisAktualnosciPage($slug: String) {
    wpNewsSingle(slug: { eq: $slug }) {
      newsSingle {
        newsSingleGallery {
          newsSingleGalleryImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1200)
              }
            }
          }
        }
        newsSingleTitle
        newsSingleDescription
        newsSingleFormTitle
        newsSingleFormFormTitle
        newsSingleFormImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1000)
            }
          }
        }
      }
      title
      date
      seo {
        ...WpSEO
      }
    }
    allWpNewsSingle(limit: 3, filter: { slug: { ne: $slug } }) {
      nodes {
        title
        slug
        date
        newsSingle {
          newsSingleExcerpt
          newsSingleGallery {
            newsSingleGalleryImg {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 800)
                }
              }
            }
          }
        }
      }
    }
  }
`
