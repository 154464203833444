import React, { useState } from 'react'

import {
  H1Light,
  H4Light,
  H2Bold,
  TextBody1,
} from 'components/atoms/Typography'

import { ImageType } from 'types/image'
import { Breadcrumbs } from 'components/modules/Breadcrumbs'
import { LazyImage } from 'components/atoms/Image'

import {
  Wrapper,
  StyledContainer,
  HeadingWrapper,
  ImagesWrapper,
  BigImageWrapper,
  ImageThumbnailsWrapper,
  ThumbnailImageWrapper,
  ArticleContentWrapper,
  BreadcrumbsWraper,
} from './NewsArticle.styles'

type Props = {
  heading: string
  date: string
  contentHeading: string
  content: string
  images: ImageType[]
  crumbs: Array<{
    name: string
    to?: string
  }>
}

export const NewsArticle: React.FC<Props> = ({
  heading,
  images,
  contentHeading,
  content,
  date,
  crumbs,
}) => {
  const [currentImage, setCurrentImage] = useState(images[0])

  return (
    <Wrapper>
      <StyledContainer>
        <HeadingWrapper>
          <H1Light
            dangerouslySetInnerHTML={{ __html: heading }}
            align="center"
          />
          <H4Light dangerouslySetInnerHTML={{ __html: date }} align="center" />
        </HeadingWrapper>
      </StyledContainer>

      <ImagesWrapper>
        <BreadcrumbsWraper>
          <Breadcrumbs crumbs={crumbs} />
        </BreadcrumbsWraper>

        <BigImageWrapper>
          <LazyImage
            src={currentImage.src}
            alt={currentImage.alt!}
            fill
            objectFit="cover"
          />
        </BigImageWrapper>

        <StyledContainer>
          <ImageThumbnailsWrapper>
            {images.map((image, index) => (
              <ThumbnailImageWrapper
                key={`${image.alt}-${index}`}
                onClick={() => setCurrentImage(images[index])}
                active={images.indexOf(currentImage) === index}
              >
                <LazyImage
                  src={image.src}
                  alt={image.alt!}
                  fill
                  objectFit="cover"
                />
              </ThumbnailImageWrapper>
            ))}
          </ImageThumbnailsWrapper>
        </StyledContainer>
      </ImagesWrapper>

      <StyledContainer>
        <ArticleContentWrapper>
          <H2Bold dangerouslySetInnerHTML={{ __html: contentHeading }} />
          <TextBody1 dangerouslySetInnerHTML={{ __html: content }} />
        </ArticleContentWrapper>
      </StyledContainer>
    </Wrapper>
  )
}
