import React from 'react'

import { CardType, NewsCard } from 'components/modules/NewsCard'

import { H1Light } from 'components/atoms/Typography'

import { Wrapper, InnerWrapper, CardsWrapper } from './MoreNews.styles'

type Props = {
  heading: string
  cards: CardType[]
}

export const MoreNews: React.FC<Props> = ({ heading, cards }) => {
  return (
    <Wrapper>
      <InnerWrapper>
        <H1Light dangerouslySetInnerHTML={{ __html: heading }} />
        <CardsWrapper>
          {cards?.map((card, index) => (
            <NewsCard
              key={`${card.heading}-${index}`}
              cardProps={card}
              autoWidth
            />
          ))}
        </CardsWrapper>
      </InnerWrapper>
    </Wrapper>
  )
}
