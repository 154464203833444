import { IconButton } from 'components/atoms/Button'
import React from 'react'
import { Link } from 'gatsby'
import { LazyImage } from 'components/atoms/Image'
import styled from 'styled-components'
import media from 'styles/media'

import { ReactComponent as ArrowRight } from 'assets/icons/arrowRight.svg'

import { H4Bold, TextBody1, TextBody2 } from 'components/atoms/Typography'
import { ImageType } from 'types/image'

const ImageWraper = styled.div`
  width: 100%;
  height: 280px;

  ${media.sm.max} {
    height: 180px;
  }
`

const NewsCardContent = styled.div`
  height: auto;

  padding: 24px;

  display: flex;
  flex-direction: column;
  gap: 32px;
  flex: 1;

  background-color: white;
  border: 1px solid #e3e3e3;

  ${media.sm.max} {
    gap: 24px;
  }
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const NewsCardContainer = styled.div<{ autoWidth?: boolean }>`
  width: ${({ autoWidth }) => (autoWidth ? 'auto' : '372px')};
  min-height: 544px;

  display: flex;
  flex-direction: column;

  text-decoration: none;

  ${media.sm.max} {
    width: ${({ autoWidth }) => (autoWidth ? 'auto' : '272px')};
    min-height: 418px;
  }

  &:hover {
    ${NewsCardContent} {
      background-color: #3da544;
      border: 1px solid #3da544;
    }

    ${TextBody1} {
      color: ${({ theme }) => theme.colors.white};
    }
    ${TextBody2} {
      color: ${({ theme }) => theme.colors.white};
    }
    ${H4Bold} {
      color: ${({ theme }) => theme.colors.white};
    }

    ${IconButton} {
      background-color: #ffffff;
      border: none;
      box-shadow: none;

      & svg,
      path {
        stroke: #3da544;
      }
    }
  }
`

export type CardType = {
  image: ImageType
  heading: string
  text: string
  date: string
  link: string
}

type NewsCardProps = {
  cardProps: CardType
  isSelected?: boolean
  autoWidth?: boolean
}

export const NewsCard = ({ cardProps, autoWidth }: NewsCardProps) => {
  const { image, heading, text, date, link } = cardProps

  return (
    <NewsCardContainer autoWidth={autoWidth} as={Link} to={link}>
      <ImageWraper>
        <LazyImage src={image.src} alt={image.alt!} fill objectFit="cover" />
      </ImageWraper>
      <NewsCardContent>
        <TextContainer>
          <TextBody2 color="gray10">{date}</TextBody2>
          <H4Bold>{heading}</H4Bold>
          <TextBody1>{text}</TextBody1>
        </TextContainer>
        <IconButton green aria-label="Open card">
          <ArrowRight />
        </IconButton>
      </NewsCardContent>
    </NewsCardContainer>
  )
}
