import styled from 'styled-components'
import media from 'styles/media'

import { H1Light } from 'components/atoms/Typography'

export const Wrapper = styled.div`
  max-width: 1376px;
  background-color: #f3f3f3;
  margin: 0 auto 100px;
  padding: 100px 120px;
  box-sizing: content-box;

  ${H1Light} {
    br {
      display: none;
    }
  }

  ${media.md.max} {
    margin-bottom: 0;
    padding: 48px 24px;

    ${H1Light} {
      br {
        display: block;
      }
    }
  }
`

export const InnerWrapper = styled.div`
  max-width: 1136px;
  margin: 0 auto;
`

export const CardsWrapper = styled.div`
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;

  ${media.xxl.max} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${media.lg.max} {
    margin-top: 32px;
    grid-template-columns: 1fr;
    gap: 24px;
  }
`
